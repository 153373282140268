/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
// import { Icon } from 'antd'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'

import { StyledHeader } from './index.style'
import { IMAGE_URL } from './content'
import { getLanguageLabelByLocale } from './utility'
import { FormattedMessage } from 'react-intl'
import MobileLanguageSwitcher from './components/MobileLanguageSwitcher'

// import config from '../../config'

// const { HERO_CONFIG } = config

class AppHeader extends Component {
  constructor (props) {
    super(props)
    this.dropdownButton = React.createRef()
    this.state = {
      productDropdownVisible: false,
      langDropdownVisible: false,
      collapsed: false,
      url: '',
      showArticles: false,
      showLocales: false,
      showOurInnovations: false
    }
  }

  handleProductClick = () => {
    this.setState({
      productDropdownVisible: !this.state.productDropdownVisible
    })
  }
  handleLangClick = () => {
    this.setState({
      langDropdownVisible: !this.state.langDropdownVisible
    })
  }

  handleClickOutside = event => {
    if (this.dropdownButton.current && !this.dropdownButton.current.contains(event.target)) {
      this.setState({
        productDropdownVisible: false,
        langDropdownVisible: false
      })
    }
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  };

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.getUrl()
  }
  componentWillMount () {
    this.setState({
      productDropdownVisible: false
    })
  }
  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  resetPoli () {
    document.getElementById('nav-check').checked = false
  }

  getUrl = (e) => {
    this.setState({ url: e })
    this.resetPoli()
  }

  render () {
    const { onLocaleChange: handleLocaleChange, locale } = this.props
    const { showLocales } = this.state
    const setShowLocales = (showLocale) => {
      this.setState({ ...this.state, showLocales: showLocale })
    }
    return (
      <div>
        <StyledHeader>
          <div className='nav'>
            <input type='checkbox' id='nav-check' />
            <div className='nav-header'>
              <div className='nav-title'>
                <Link id='link_header_Pasarpolis.com' to='/' onClick={() => this.getUrl('')}>
                  <img src={IMAGE_URL.logo} alt='pasarpolis_logo' width='100%' />
                </Link>
              </div>
            </div>
            <div className='nav-btn'>
              <label for='nav-check'>
                <span />
                <span />
                <span />
              </label>
            </div>
            <div className={`nav-links-mobile ${locale === 'vn' ? 'end' : ''} `}>
              <div className='nav-mobile-top'>
                <img src={IMAGE_URL.logo} alt='pasarpolis_logo' width='100px' />
              </div>
              <div className='nav-mobile-middle'>
                <NavLink id='link_header_about' className={this.state.url === '' && 'nav-bar-selcted'} to='/' onClick={() => this.getUrl('')}>
                  <FormattedMessage id='Beranda' />
                </NavLink>
                <NavLink id='link_header_about' className={this.state.url === 'about' && 'nav-bar-selcted'} to='/about' onClick={() => this.getUrl('about')}>
                  <FormattedMessage id='Tentang Kami' />
                </NavLink>
                <NavLink id='link_header_about' className={this.state.url === 'product' && 'nav-bar-selcted'} to='/product' onClick={() => this.getUrl('product')} >
                  <FormattedMessage id='Produk' />
                  <span className='nav-bar-hover' />
                </NavLink>
                <NavLink id='link_header_Klaim' className={this.state.url === 'claim' && 'nav-bar-selcted'} to='/claim' onClick={() => this.getUrl('claim')}>
                  <FormattedMessage id='Ajukan Klaim' />
                </NavLink>
                <NavLink id='link_header_Faq' className={this.state.url === 'faq' && 'nav-bar-selcted'} to='faq' onClick={() => this.getUrl('faq')}>
                  <FormattedMessage id='FAQ' />
                </NavLink>
              </div>
              {/* <div className='nav-mobile-bottom'>
                <MobileLanguageSwitcher
                  onLocaleChange={handleLocaleChange}
                  currentLocale={locale}
                  showName
                />
              </div> */}
            </div>
            <div className={`nav-links ${locale === 'vn' ? 'end' : ''} `}>
              {locale !== 'vn'
                ? (<div className='navbar nav-left'>
                  <NavLink id='link_header_about' className={this.state.url === '' && 'nav-bar-selcted'} to='/' onClick={() => this.getUrl('')} >
                    <FormattedMessage id='Beranda' />
                    <span className='nav-bar-hover' />
                  </NavLink>
                  <NavLink id='link_header_about' className={this.state.url === 'about' && 'nav-bar-selcted'} to='/about' onClick={() => this.getUrl('about')} >
                    <FormattedMessage id='Tentang Kami' />
                    <span className='nav-bar-hover' />
                  </NavLink>
                  <NavLink id='link_header_about' className={this.state.url === 'product' && 'nav-bar-selcted'} to='/product' onClick={() => this.getUrl('product')} >
                    <FormattedMessage id='Produk' />
                    <span className='nav-bar-hover' />
                  </NavLink>
                  <NavLink id='link_header_Klaim' className={this.state.url === 'claim' && 'nav-bar-selcted'} to='/claim' onClick={() => this.getUrl('claim')}>
                    <FormattedMessage id='Ajukan Klaim' />
                    <span className='nav-bar-hover' />
                  </NavLink>
                  <NavLink id='link_header_Faq' className={this.state.url === 'faq' && 'nav-bar-selcted'} to='faq' onClick={() => this.getUrl('faq')}>
                    <FormattedMessage id='FAQ' />
                    <span className='nav-bar-hover' />
                  </NavLink>
                </div>)
                : null
              }
            </div>
            <div className='navbar nav-right'>
              <div className='navbar__claim-ctn'>
                <div className='navbar__claim-button'>
                  <a href={process.env.REACT_APP_CLAIMS_LINK}><FormattedMessage id='Ajukan Klaim' /></a>
                </div>
              </div>
            </div>
          </div>
        </StyledHeader >
      </div >
    )
  }
}

export default connect(state => ({}), {
})(AppHeader)
